import React from 'react'
import brigitteJpg from '../images/brigitte.jpg'
import brigitteWebp from '../images/brigitte.webp'
import IndexLayout from '../layouts'

const oBriggiteCV = (
  <div>
    <h2>Lebensweg</h2>
    <p style={{ textAlign: 'center' }}>
      <i>
        „Ich fühle mich dort hingezogen, wo ich die eigenen Verletzungen spüre.
        <br />
        Hier findet Bewegung, Wandel und schöpferische Kraft statt."
      </i>
    </p>
    <table id="team__brigitteTable">
      <tbody>
        <tr>
          <td style={{ verticalAlign: 'top' }}>2018 – lfd.</td>
          <td>regelmäßiges Schreiben bei den Schreibnomaden Kufstein</td>
        </tr>
        <tr>
          <td style={{ verticalAlign: 'top' }}>2017/18</td>
          <td>Musikalische Gruppen(beg)leitung bei Margie Sackl</td>
        </tr>
        <tr>
          <td style={{ verticalAlign: 'top' }}>2017</td>
          <td>Empower yourself bei Bahar Yilmaz und Jeffrey Kastenmüller</td>
        </tr>
        <tr>
          <td style={{ verticalAlign: 'top' }}>2016</td>
          <td>Märchenarbeit (Methodenlehrgang zur Arbeit mit Kindern und Jugendlichen) bei Roswitha Betz und Magarete Ringler</td>
        </tr>
        <tr>
          <td style={{ verticalAlign: 'top' }}>2015</td>
          <td>Lerne dein Krafttier kennen bei Sophia Winkler</td>
        </tr>
        <tr>
          <td style={{ verticalAlign: 'top' }}>2014/15</td>
          <td>Channelausbildung bei Seminarzentrum für Bewusstseinsbildung „Shekaina“</td>
        </tr>
        <tr>
          <td style={{ verticalAlign: 'top' }}>2010 – 2013</td>
          <td>Akademie der Lebens- und Sozialberatung bei DDr Bánffy</td>
        </tr>
        <tr>
          <td style={{ verticalAlign: 'top' }}>2010 – 2011</td>
          <td>Lehrgang „Vorbereitungskurs für Pflegeeltern“ bei Mag. Romana Hinteregger</td>
        </tr>
        <tr>
          <td style={{ verticalAlign: 'top' }}>2008 – 2010</td>
          <td>Yogaausbildung bei „runde Erde – Hand in Hand“ P. Thomaset / H. Gumpoltsberger</td>
        </tr>
        <tr>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td style={{ verticalAlign: 'top' }}>2018 – lfd.</td>
          <td>Projekt „Marjam – Patinnen für geflüchtete Frauen in Tirol“</td>
        </tr>
        <tr>
          <td style={{ verticalAlign: 'top' }}>2013 – lfd.</td>
          <td>Projekt „Sternenstaub“ gemeinsam mit Sabrina Lackner</td>
        </tr>
        <tr>
          <td style={{ verticalAlign: 'top' }}>2013 – lfd.</td>
          <td>eigene Praxis für Beratungen</td>
        </tr>
        <tr>
          <td style={{ verticalAlign: 'top' }}>2008 – lfd.</td>
          <td>Yogakurse</td>
        </tr>
        <tr>
          <td style={{ verticalAlign: 'top' }}>2000 – lfd.</td>
          <td>eigenes Architekturbüro nach Studium an der Fakultät für Technische Wissenschaften – Universität Innsbruck</td>
        </tr>
      </tbody>
    </table>
    <p>
      Teilnahme an diversen Workshops, Seminaren, Fortbildungen und Aufstellungen:
      <br />
      Ritualarbeit (Altes gehen lassen, Neues stärken)
      <br />
      Schwitzhütten
      <br />
      Psychodramen, Bibliodramen, Familienaufstellungen
      <br />
      Workshop Kinesiologie
    </p>
    <p>
      Was ich in meinem beruflichen und privaten Leben, auch als Pflegemutter immer wieder erleben darf, ist, dass ich nur in Freiheit etwas
      geben und eine gesunde Beziehung aufbauen und leben kann. Ich brauche das Vertrauen, mich auf meine Weise ausdrücken zu dürfen.
    </p>
  </div>
)

export default class Team extends React.Component {
  render() {
    return (
      <IndexLayout>
        <h1 className="sectionTitle">Über mich</h1>
        <p>als Lebens- und Sozialberaterin unterliege ich der Verschwiegenheitspflicht</p>
        <div className="team__teamWrapper">
          <div className="team__centerLink">
            <div className="team__linkBrigitte">
              <picture>
                <source srcSet={brigitteWebp} type="image/webp" />
                <source srcSet={brigitteJpg} type="image/jpeg" />
                <img src={brigitteJpg} width="125px" style={{ borderRadius: '100%' }} alt="Brigitte" />
              </picture>
              <br />
              <span className="team__linkBrigitte">Brigitte</span>
            </div>
          </div>
        </div>
        {oBriggiteCV}
        <br />
        <br />
      </IndexLayout>
    )
  }
}
